<template>
  <v-container fluid>
      <v-snackbar
        v-model="exibe_alerta"
        timeout="3000"
        :color="alerta_color"
        >
        {{ alerta }}
        </v-snackbar> 
      <v-form ref="form" v-model="form_valido" lazy-validation> 

        <v-row v-if="loading" >
          <v-col>
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row> 

        <v-row v-if="loading" >
          <v-col>
            <div class="text-center" >Carregando</div>
          </v-col>
        </v-row> 

        <!-- colocar aqui o conteúdo -->

        <v-row v-if="!loading" >
          <v-col>
            
            <v-stepper  flat v-model="etapa">
              <v-stepper-header>
                <v-stepper-step 
                  step="0" 
                  :complete="etapa > 0">
                  Início
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step 
                  step="1"
                  :complete="etapa > 1">
                  Dados Gerais
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step 
                  step="2"
                  :complete="etapa > 2">
                  Dados Verificados
                </v-stepper-step>

              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row> 

        <!-- Etapa Zero -->

        <v-row v-if="!loading && etapa == 0" >
          <v-col>
            <v-card flat color="#e2eaf0">

              <v-card-title class="text-h5 grey--text text--darken-3">
                Alteração de Dados Cadastrais
              </v-card-title>

              <v-card-text v-if="(pode_iniciar || pode_continuar) && (requerimento.situacao =='L' || requerimento.situacao =='E' || requerimento.situacao =='F' || requerimento.situacao =='N' || requerimento.situacao =='') ">
                <p class="text-body-1 font-weight-medium text-justify">
                A alteração de dados cadastrais mudou. Agora você deve preencher um requerimento e encaminhar para o Serpros.
                O preenchimento é dividido em duas etapas: </p>

                <p class="text-body-1 font-weight-medium text-justify">
                  <v-icon
                    class="mt-n1"
                    color="grey darken-2"
                  >
                    mdi-numeric-1-circle
                  </v-icon>
                  Preencher os dados gerais.
                </p>
                <p class="text-body-1 font-weight-medium text-justify">
                  <v-icon
                    class="mt-n1"
                    color="grey darken-2"
                  >
                    mdi-numeric-2-circle
                  </v-icon>
                  Preencher dados verificados e anexar os documentos comprobatórios.
                </p>
                <p class="text-body-1 font-weight-medium text-justify">
                  Caso seja necessário atualizar os dados verificados o requerimento será encaminhado para análise dos documentos, caso contrário será deferido automaticamente. Não é necessário preencher tudo de uma só vez. O requerimento pode ser salvo e você pode retornar para completar o preenchimento depois.
                </p>

              </v-card-text>

              <v-card-text v-if="requerimento.situacao == 'A' || requerimento.situacao == 'C' || requerimento.situacao == 'E'">
                <p class="text-body-1 font-weight-medium text-justify">
                Acompanhe aqui o andamento do seu requerimento de alteração de dados cadastrais. </p>

                <v-alert v-for="mensagem in mensagens"  text  color="blue darken-4" ><b>{{mensagem.data_gravacao.substr(8,2)+'/'+mensagem.data_gravacao.substr(5,2)+'/'+mensagem.data_gravacao.substr(0,4)+' '+mensagem.data_gravacao.substr(11,2)+':'+mensagem.data_gravacao.substr(14,2)+':'+mensagem.data_gravacao.substr(17,2)}}</b> - {{mensagem.mensagem}}</v-alert>

              </v-card-text>

              <v-card-text>
                <v-btn v-if="pode_iniciar" rounded color="primary"   @click="iniciarRequerimento"> Criar novo requerimento </v-btn>
                <v-btn v-if="pode_continuar" rounded color="primary"   @click="buscaRequerimento"> Continuar requerimento </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> 


        <!-- Etapa 1 -->

        <v-row v-if="!loading && etapa == 1" >
          <v-col>

            <v-row >
              <v-col>
                
                  <v-card color="#ECEFF1" flat>
                    <v-toolbar color="primary" flat dense dark>
                          <v-toolbar-title>
                            <v-row align="center">
                                <v-col class="shrink">Dados Gerais</v-col>
                            </v-row>
                          </v-toolbar-title>
                    </v-toolbar>
                        <v-card-text>
                        <div>
                          <v-row class="mb-n5">

                            <v-col cols="12" md="3">
                              <div class="text-subtitle-1 mt-2">  <b>CPF: {{participante.cpf.substring(0,3)}}.{{participante.cpf.substring(3,6)}}.{{participante.cpf.substring(6,9)}}-{{participante.cpf.substring(9,11)}} </b> </div>
                            </v-col>  
                            
                            <v-col cols="12" md="2"> 
                              <v-text-field :readonly="somente_leitura"  dense v-model="participante.naturalidade" label="* Naturalidade"  :rules="[...Rules.Required, ...maxLength(30)]"></v-text-field>
                            </v-col>  
                            
                            <v-col cols="12" md="2">
                              <v-select
                                :readonly="somente_leitura" 
                                label="Escolaridade"
                                v-model="participante.id_escolaridade"
                                :items="escolaridade"
                                item-text="descricao"
                                item-value="id"
                                
                                dense
                              > 
                              </v-select>
                            </v-col> 

                            <v-col cols="12" md="2"> 
                              <v-select
                                :readonly="somente_leitura" 
                                label="* Aposentado INSS"
                                v-model="participante.aposentado_inss"
                                :items="sim_nao"
                                item-text="descricao"
                                item-value="id"
                                
                                dense
                                :rules="[...Rules.Required, ...aposInss(participante.aposentado_inss, participante.sexo)]"
                              > 
                              </v-select>
                            </v-col> 
                          </v-row>  

                          
                        </div>  
                        </v-card-text>
                  </v-card>
              </v-col>
            </v-row>

            

            <v-row >
              <v-col>
                
                  <v-card color="#ECEFF1" flat>
                    <v-toolbar color="primary" flat dense dark>
                          <v-toolbar-title>
                            <v-row align="center">
                                <v-col class="shrink">Informações de Endereço e Contatos</v-col>
                            </v-row>
                          </v-toolbar-title>
                    </v-toolbar>
                        <v-card-text>
                        <div>
                          <v-row class="mb-n5">
                            <v-col cols="12" md="3">
                              <v-select
                                :readonly="somente_leitura" 
                                label="* País"
                                v-model="participante.id_pais"

                                :items="paises"
                                item-text="nome"
                                item-value="id"
                                
                                dense
                                :rules="Rules.Required"
                                @change="VerificaPais"
                              > 
                              </v-select>
                            </v-col>  
                            <v-col cols="12" md="2">
                              <v-text-field 
                                :readonly="somente_leitura" 
                                :disabled = "participante.id_pais != 31"
                                dense 
                                v-model="participante.cep" 
                                label="* CEP"  
                                :rules="[...maxLength(8), ...cep(participante.cep)]" 
                                @change="consultaCEP(participante.cep, participante)"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-select
                                :readonly="somente_leitura" 
                                :disabled = "participante.id_pais != 31"
                                label="* Estado"
                                v-model="participante.estado"
                                :items="estados"
                                dense
                                :rules="[...Estado(participante.cep,participante.estado)]"
                              > 
                              </v-select>
                            </v-col>  
                            <v-col cols="12" md="5"> 
                              <v-text-field :readonly="somente_leitura"  dense v-model="participante.cidade" label="* Cidade"  :rules="[...Rules.Required, ...maxLength(30)]"></v-text-field>
                            </v-col>  



                          </v-row>  

                          <v-row class="mb-n5">

                            <v-col cols="12" md="3">
                              <v-text-field :readonly="somente_leitura"  dense v-model="participante.bairro" label="* Bairro"  :rules="[...Rules.Required, ...maxLength(20)]"></v-text-field>
                            </v-col>  
                            
                            <v-col cols="12" md="4"> 
                              <v-text-field :readonly="somente_leitura"  dense v-model="participante.endereco" label="* Endereço"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2"> 
                              <v-text-field :readonly="somente_leitura"  dense v-model="participante.numero" label="* Número"  :rules="[...Rules.Required, ...maxLength(10)]"></v-text-field>
                            </v-col>  
                            <v-col cols="12" md="3"> 
                              <v-text-field :readonly="somente_leitura"  dense v-model="participante.complemento" label="Complemento" :rules="maxLength(40)" ></v-text-field>
                            </v-col> 
    
                          </v-row>

                          <v-row class="mb-n5">
                            <v-col cols="12" md="3">
                              <v-text-field :readonly="somente_leitura"  ref="email_pessoal" autocomplete="none" dense v-model="participante.email_pessoal" label="* Email Pessoal"  :rules="[...Rules.Required, ...maxLength(60), ...email(participante.email_pessoal)]" > </v-text-field>
                            </v-col> 
                            <v-col cols="12" md="3">
                              <v-text-field :readonly="somente_leitura"  autocomplete="none" dense v-model="participante.email_extra" label="Email Pessoal Extra" :rules="[...maxLength(60), ...email(participante.email_extra)]"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="participante.celular" label="* Celular"  :rules="[...Rules.Required, ...maxLength(15), ...telefone(participante.celular)]"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="participante.telefone_alternativo" label="Telefone Alternativo" :rules="[...maxLength(15)]" ></v-text-field>
                            </v-col> 

                          </v-row>

                        </div>  
                        </v-card-text>
                  </v-card>
              </v-col>
            </v-row>


            <v-row >
              <v-col>
                
                  <v-card color="#ECEFF1" flat>
                    <v-toolbar color="primary" flat dense dark>
                          <v-toolbar-title>
                            <v-row align="center">
                                <v-col class="shrink">Pessoa Politicamente Exposta</v-col>
                            </v-row>
                          </v-toolbar-title>
                    </v-toolbar>
                        <v-card-text>
                          <v-row class="mb-n5">
                            <v-col cols="12" md="12">
                              <span class="text-body-1 font-weight-medium text-justify">
                                Pessoa Politicamente Exposta é um indivíduo que tenha exercido cargos, funções ou empregos públicos, políticos, militares ou magistrados nos últimos cinco anos. Também se enquadram nessa definição familiares, representantes e pessoas de convívio próximo aos citados acima. Você ou seus familiares, representantes ou pessoas de seu relacionamento próximo se enquadram na condição de Pessoa Politicamente Exposta?
                              </span>
                              
                            </v-col> 
 
                          </v-row>
                          <v-row class="mb-n5">
                            
                            <v-col cols="12" >
                              <v-radio-group v-model="participante.pep" row :rules="[...pepObrigatorio()]" >
                                <v-radio
                                  key=0
                                  label="SIM"
                                  value="S"
                                ></v-radio>
                                <v-radio
                                  key=1
                                  label="NÃO"
                                  value="N"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>  
                          </v-row>

                          <v-card :color="participante.pep == 'N' ? '#ECEFF1' : 'white'" elevation="1" v-if="participante.pep == 'S'">
                            <v-card-text>
                              <v-row class="mb-n5">
                                
                                <v-col cols="12" md="3">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="participante.cpf_pep" label="* CPF"  :rules="[...Rules.Required, ...maxLength(11), ...cpf(participante.cpf_pep)]"></v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="6">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="participante.nome_pep" label="* Nome da pessoa politicamente exposta"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                                </v-col>  

                                <v-col cols="12" md="3">
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="* Parentesco"
                                    v-model="participante.id_parentesco_pep"
                                    :items="parentesco"
                                    item-text="descricao"
                                    item-value="id"
                                    dense
                                    :rules="[...Rules.Required]" 
                                  > 
                                  </v-select>
                                </v-col>

                              </v-row>
                              <v-row>

                                <v-col cols="12" md="6">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="participante.cargo_pep" label="* Cargo ocupado"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="2">
                                  <v-text-field  :readonly="somente_leitura"  type="date" dense v-model="participante.data_inicio_pep" label="* Data de início"  :rules="[...Rules.Required]"  ></v-text-field>
                                </v-col> 

                                <v-col cols="12" md="2">
                                  <v-text-field  :readonly="somente_leitura"  type="date" dense v-model="participante.data_termino_pep" label="* Data de término"    ></v-text-field>
                                </v-col>  
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                  </v-card>
              </v-col>
            </v-row>

            <v-row >
              <v-col>
                
                  <v-card color="#ECEFF1" flat>
                    <v-toolbar color="primary" flat dense dark>
                          <v-toolbar-title>
                            <v-row align="center">
                                <v-col class="shrink">Reportável FATCA</v-col>
                            </v-row>
                          </v-toolbar-title>
                    </v-toolbar>
                      <v-card-text>
                        <v-row class="mb-n5">
                          <v-col cols="12" md="12">
                            <span class="text-body-1 font-weight-medium text-justify">
                              FATCA é um formulário que o cidadão americano que recebe rendimento no Brasil, deve preencher e encaminhar para a Receita Federal. Você é reportável FATCA?
                            </span>
                            
                          </v-col> 

                        </v-row>
                        <v-row class="mb-n5">
                          <v-col cols="12" >
                            <v-radio-group v-model="participante.responsavel_fatca" row @change="clickResponsavelFATCA" :rules="Rules.Required">
                              <v-radio
                                key=0
                                label="SIM"
                                value="S"
                              ></v-radio>
                              <v-radio
                                key=1
                                label="NÃO"
                                value="N"
                              ></v-radio>
                            </v-radio-group>
                          </v-col> 
                          
                        </v-row>

                        <v-card v-if="participante.responsavel_fatca =='S'" :color="participante.responsavel_fatca == 'N' ? '#ECEFF1' : 'white'" elevation="1" :disabled="participante.responsavel_fatca == 'N'">
                          <v-card-text>
                            <v-row class="mb-n5">
                              <v-col cols="12" md="12">
                                <span class="text-body-1 font-weight-medium text-justify">
                                  1 - Você é residente fiscal em algum país que não seja o Brasil?
                                </span>
                                
                              </v-col> 
  
                            </v-row>

                            <v-row class="mb-n5">
                              <v-col cols="12" >
                                <v-radio-group v-model="participante.residente_fiscal_exterior" row @change="clickResidenteExterior" :rules="Rules.Required">
                                  <v-radio
                                    key=0
                                    label="SIM"
                                    value="S"
                                  ></v-radio>
                                  <v-radio
                                    key=1
                                    label="NÃO"
                                    value="N"
                                  ></v-radio>
                                  
                                </v-radio-group>
                              </v-col> 
                              
                            </v-row>

                            <div v-if="participante.residente_fiscal_exterior == 'S' && participante.responsavel_fatca == 'S'">
                              <v-row class="mb-n5">
                                <v-col cols="12" md="4">
                                  <span class="text-body-1 font-weight-medium text-justify">
                                    Em qual país você é residente fiscal, além de no Brasil?
                                  </span>
                                  
                                </v-col> 

                                <v-col cols="12" md="4">
                                  <span class="text-body-1 font-weight-medium text-justify">
                                    Informe o número TIN (Taxpayer Identification Number).
                                  </span>
                                  
                                </v-col> 
    
                              </v-row>
                              <v-row class="mb-n5">

                                <v-col cols="12" md="4">
                                  
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="* País"
                                    v-model="participante.pais_pep_1"
                                    :items="paises"
                                    item-text="nome"
                                    item-value="id"
                                    dense
                                    :rules="Rules.Required"
                                  > 
                                  </v-select>
                                </v-col> 

                                <v-col cols="12" md="4">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="participante.tin_1" :rules="[...umDosDois(participante.tin_1,participante.tin_1_nao_se_aplica), ...maxLength(30)]"></v-text-field>
                                </v-col> 
                                <v-col cols="12" md="4">
                                  <v-checkbox
                                    class="mt-0"
                                    v-model="participante.tin_1_nao_se_aplica"
                                    label="TIN não disponível"
                                    :rules="[...umDosDois(participante.tin_1,participante.tin_1_nao_se_aplica)]"
                                  ></v-checkbox>
                                </v-col> 
    
                              </v-row>
                              
                          

                            </div> 

                              <v-row class="mb-n5">
                                <v-col cols="12" >
                                  <span class="text-body-1 font-weight-medium text-justify">
                                    2 - Você é um cidadão americano?
                                  </span>
                                  
                                </v-col> 
    
                              </v-row>

                              <v-row class="mb-n5">
                                <v-col cols="12">
                                  <v-radio-group v-model="participante.cidadao_americano" row>
                                    <v-radio
                                      key=0
                                      label="SIM"
                                      value="S"
                                    ></v-radio>
                                    <v-radio
                                      key=1
                                      label="NÃO"
                                      value="N"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-col> 
                                
                              </v-row>
                              
                          </v-card-text>
                        </v-card> 
                      </v-card-text>
                  </v-card>
              </v-col>
            </v-row>


            <v-row class="mb-5" v-if=" aposentado_pensionista == null"> 
              <v-col>
                  <v-card color="#ECEFF1" flat>
                    <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Informações das Empresas</v-col>
                        </v-row>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <div>
                        <v-container
                          v-for="empresa in empresas"
                          :key="empresa.id"
                        >
                          <v-row class="mb-n5">
                            <v-col cols="12" md="4">
                              <v-text-field  :readonly="somente_leitura"  dense v-model="empresa.nome" label="* Nome da Empresa"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                            </v-col>  
                            <v-col cols="12" md="2"> 
                              <v-select
                                :readonly="somente_leitura" 
                                label="* Categorias"
                                v-model="empresa.id_tipo_servico"
                                :items="tipoTempoServico"
                                item-text="descricao"
                                item-value="id"
                                
                                dense
                                :rules="Rules.Required"
                              > 
                              </v-select>
                            </v-col>  
                            <v-col cols="12" md="2">
                              <v-text-field  :readonly="somente_leitura"  type="date" dense v-model="empresa.data_admissao" label="* Data de admissão"  :rules="[...Rules.Required, ...DataMaiorQAtual(empresa.data_admissao)]" @change="IdadeAdmissao(empresa.data_admissao)" ></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-text-field  :readonly="somente_leitura"  type="date" dense v-model="empresa.data_demissao" label="* Data de demissão"  :rules="DataMenor(empresa.data_admissao, empresa.data_demissao)" ></v-text-field>
                            </v-col>  
                            <v-col cols="12" md="2">
                              <v-btn v-if="!somente_leitura" rounded color="#B0BEC5" @click="DelEmpresa(empresa.id)" > <v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                            </v-col> 
                          </v-row>
                        </v-container>
                      </div>  
                    </v-card-text>
                    <v-card-actions class="mb-n5">
                      <v-spacer></v-spacer>
                      <v-btn v-if="!somente_leitura"  rounded color="#B0BEC5" @click="AddEmpresa"> <v-icon>mdi-plus-circle-outline</v-icon> Adicionar empresa</v-btn>
                    </v-card-actions> 
                  </v-card>
              </v-col>
            </v-row>

            <v-row  v-if="eh_assistido_ps2">
              <v-col>
                <v-card color="#ECEFF1" flat>
                  <v-card-title >
                    Atenção aposentados do PSII!
                  </v-card-title>

                  <v-card-text >
                    <span class="text-body-1 font-weight-medium text-justify">
                      <p>Se for necessário incluir novos beneficiários para pensão por morte ou alterar informações dos atuais beneficiários tais como 
                      cpf, data de nascimento, sexo, parentesco ou invalidez, saiba que essa mudança pode acarretar uma alteração no valor atual do seu benefício. 
                      Mas não se preocupe, nada vai acontecer sem o seu consentimento.
                      </p> 
                      <p>Seu requerimento vai ser encaminhado para uma análise atuarial e, se for o caso, 
                      será devolvido para você com o novo valor do seu benefício para sua aprovação. 
                      As alterações nas informações dos seus beneficiários de pensão por morte, assim como o ajuste no valor do seu benefício somente serão efetivados
                      após o seu aceite.
                      </p>
                      Alterações no endereço, telefone e e-mail dos beneficiários não estão sujeitas a análise atuarial e não provocarão recálculo do benefício.
                    </span>
                  </v-card-text>
                </v-card>    

              </v-col>
            </v-row>  

            <v-row  v-if="this.participante_planos.length != 0">
              <v-col>
                  <v-card color="#ECEFF1" flat>
                    <v-toolbar color="primary" flat dense dark>
                          <v-toolbar-title>
                            <v-row align="center">
                                <v-col class="shrink">Informações dos Beneficiários e Designados</v-col>
                            </v-row>
                          </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text >
                        <v-container
                          v-for="dependente in dependentes"
                          :key="dependente.id"
                          fluid
                        >
                          <v-card color="#ECEFF1">
                            <v-card-text>
                              <v-row class="mb-n5">
                                <v-col cols="12" md="4">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="dependente.nome" label="* Nome"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                                </v-col> 
                                <v-col cols="12" md="2">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="dependente.cpf" label="* CPF"  :rules="[...Rules.Required, ...maxLength(11), ...cpf(dependente.cpf)]"></v-text-field>
                                </v-col> 
                                <v-col cols="12" md="2">
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="* Parentesco"
                                    v-model="dependente.id_parentesco"
                                    :items="parentesco"
                                    item-text="descricao"
                                    item-value="id"
                                    dense
                                    :rules="[...Rules.Required]"
                                    @input="Bigamia()" 
                                  > 
                                  </v-select>
                                </v-col>
                                <v-col cols="12" md="2">
            
                                  <v-text-field :readonly="somente_leitura"  type="date" dense v-model="dependente.data_nascimento" label="* Data de nascimento"  :rules="[...Rules.Required]" @change="IdadeParente(dependente.id_parentesco, participante.data_nascimento, dependente.data_nascimento);" ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="* Inválido"
                                    v-model="dependente.invalido"
                                    :items="sim_nao"
                                    item-text="descricao"
                                    item-value="id"
                                    
                                    dense
                                    :rules="Rules.Required"
                                  > 
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row class="mb-n5">
                                <v-col cols="12" md="2">
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="* Sexo"
                                    v-model="dependente.sexo"
                                    :items="sexo"
                                    item-text="descricao"
                                    item-value="id"
                                    dense
                                    :rules="[...Rules.Required]"
                                  > 
                                  </v-select>
                                </v-col> 
                                <v-col cols="12" md="2">
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="Escolaridade"
                                    v-model="dependente.id_escolaridade"
                                    :items="escolaridade"
                                    item-text="descricao"
                                    item-value="id"
                                    
                                    dense
                                  > 
                                  </v-select>
                                </v-col> 

                                <v-col cols="12" md="2">
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="* Dependente para IR"
                                    v-model="dependente.dependente_irrf"
                                    :items="sim_nao_vazio"
                                    item-text="descricao"
                                    item-value="id"
                                    dense
                                    :rules="[...Rules.Required]"

                                  > 
                                  </v-select>
                                </v-col> 

                                <v-col cols="12" md="2">
                                  <v-text-field  
                                    :readonly="somente_leitura" 
                                    dense 
                                    v-model="dependente.cep" 
                                    label="CEP" 
                                    :rules="[...maxLength(8)]"
                                    append-outer-icon="mdi-text-search" 
                                    @click:append-outer="consultaCEP(dependente.cep, dependente)"
                                  ></v-text-field>
                                                                  
                                </v-col>
                                <v-col cols="12" md="1">
                                  <v-select
                                    :readonly="somente_leitura" 
                                    label="Estado"
                                    v-model="dependente.estado"
                                    :items="estados"
                                    dense
                                  > 
                                  </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="dependente.cidade" label="Cidade" :rules="[...maxLength(30)]"></v-text-field>
                                </v-col>
                                

                              </v-row>

                              <v-row class="mb-n5">
                                <v-col cols="12" md="4">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="dependente.endereco" label="Endereço" :rules="[...maxLength(60)]"></v-text-field>
                                </v-col> 
                                <v-col cols="12" md="2">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="dependente.numero" label="Número" :rules="[...maxLength(10)]"></v-text-field>
                                </v-col> 
                                <v-col cols="12" md="4">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="dependente.complemento" label="Complemento" :rules="[...maxLength(40)]"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="dependente.bairro" label="Bairro" :rules="[...maxLength(20)]"></v-text-field>
                                </v-col>

                                
                              </v-row>
                              <v-row class="mb-n5">

                                <v-col cols="12" md="4">
                                  <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.email_pessoal" label="* Email Pessoal"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.email_extra" label="Email Pessoal Extra" :rules="[...maxLength(60)]"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.celular" label="* Celular"  :rules="[...Rules.Required, ...maxLength(15)]"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.telefone_alternativo" label="Telefone Alternativo" :rules="[...maxLength(15)]"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-container
                              v-for="plano in dependente.planos"
                              :key="plano.id"
                              fluid
                            >
                              <v-card color="#CFD8DC">
                                <v-card-text>
                                  <v-row class="mb-n5">
                                    <v-col cols="12" md="3">
                                      <v-select
                                        :readonly="somente_leitura" 
                                        label="* Plano"
                                        v-model="plano.id_plano"
                                        :items="planos"
                                        item-text="sigla"
                                        item-value="id"
                                        readonly
                                        dense
                                      > 
                                      </v-select>
                                    </v-col> 
                                    <v-col cols="12" md="3">
                                      <v-select
                                        :readonly="somente_leitura" 
                                        label="* Beneficiário para pensão"
                                        v-model="plano.beneficiario"
                                        :items="sim_nao"
                                        item-text="descricao"
                                        item-value="id"
                                        dense
                                        :rules="[...Rules.Required, ...Finalidade(dependente.planos, dependente.dependente_irrf)]"

                                      > 
                                      </v-select>
                                    </v-col> 
                                    <v-col cols="12" md="3">
                                      <v-select
                                        :readonly="somente_leitura" 
                                        label="* Designado para pecúlio"
                                        v-model="plano.designado"
                                        :items="sim_nao"
                                        item-text="descricao"
                                        item-value="id"
                                        dense
                                        :rules="[...Rules.Required, ...Finalidade(dependente.planos, dependente.dependente_irrf)]"
                                      > 
                                      </v-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      <v-text-field :readonly="somente_leitura"  dense v-model="plano.percentual" :rules="[...Rateio(plano.id_plano, plano.percentual, plano.designado)]"  label="Perc. pecúlio"  ></v-text-field>
                                    </v-col>
                                    <v-col>
                                      <v-spacer></v-spacer>
                                      <!--<v-btn rounded color="#B0BEC5"  @click=DelPlano(plano.id) > <v-icon>mdi-minus-circle-outline</v-icon></v-btn> -->
                                    </v-col>
                                  </v-row> 
                                </v-card-text>
                              </v-card>    
                            </v-container> 
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn v-if="!somente_leitura"  rounded color="#B0BEC5" @click=DelDependente(dependente.id) > <v-icon>mdi-minus-circle-outline</v-icon> Excluir pessoa</v-btn>
                            <!-- <v-btn rounded color="#B0BEC5"  @click=AddPlano(dependente.id_dependente) > <v-icon>mdi-plus-circle-outline</v-icon> Adicionar Plano</v-btn> -->
                            </v-card-actions> 
                          </v-card>  
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="!somente_leitura"  class="mb-2" rounded color="#B0BEC5" @click="AddDependente" > <v-icon>mdi-plus-circle-outline</v-icon> Adicionar Beneficiário ou Designado </v-btn>
                    </v-card-actions> 
                  </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row> 
        <!-- Fim Etapa 1 -->

        <!-- Etapa 2 -->

        <v-row v-if="!loading && etapa == 2" >
          <v-col>

            <v-row >
              <v-col>
                
                  <v-card color="#ECEFF1" flat>
                    <v-toolbar color="primary" flat dense dark>
                          <v-toolbar-title>
                            <v-row align="center">
                                <v-col class="shrink">Dados Verificados</v-col>
                            </v-row>
                          </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                      <v-row class="mb-n5">
                        <v-col cols="12" md="12">
                          <span class="text-body-1 font-weight-medium text-justify">
                            Se for necessário alterar os dados abaixo, você precisará encaminhar documentos comprobatórios e seu recadastramento somente será confirmado após análise do Serpros. Deseja alterar os dados abaixo?
                          </span>
                          
                        </v-col> 

                      </v-row>
                      <v-row class="mb-n5">
                        
                        <v-col cols="12" md="2">
                          <v-radio-group v-model="participante.alterar_dados_verificados" row @change="VerificaSePodeEnviar">
                            <v-radio
                              key=0
                              label="SIM"
                              value="S"
                            ></v-radio>
                            <v-radio
                              key=1
                              label="NÃO"
                              value="N"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>  
                      </v-row>
                       
                    
                      <v-card :color="participante.alterar_dados_verificados == 'N' ? '#ECEFF1' : 'white'" elevation="1" :disabled="participante.alterar_dados_verificados == 'N'">
                        <v-card-text>
                          <div>
                            <v-row class="mb-n5">
                             
                              <v-col cols="12" md="6"> 
                                <v-text-field :readonly="somente_leitura" dense v-model="participante.nome" label="* Nome" :rules="[...Rules.Required, ...maxLength(60)]" ></v-text-field>
                              </v-col>  
                              <v-col cols="12" md="3">
                                <v-text-field :readonly="somente_leitura"  type="date" dense v-model="participante.data_nascimento" label="* Data de nascimento" :rules="[...Rules.Required]"  ></v-text-field>
                              </v-col> 
                              <v-col cols="12" md="3"> 
                                <v-select
                                  :readonly="somente_leitura"
                                  label="Sexo"
                                  v-model="participante.sexo"
                                  :items="sexo"
                                  item-text="descricao"
                                  item-value="id"
                                  dense
                                > 
                                </v-select>
                              </v-col> 
                    
                            </v-row>

                            <v-row class="mb-n5">
                              <v-col cols="12" md="4">
                                <v-text-field :readonly="somente_leitura"  dense v-model="participante.pai" label="Nome do pai" :rules="maxLength(60)" ></v-text-field>
                              </v-col>  
                              <v-col cols="12" md="4"> 
                                <v-text-field :readonly="somente_leitura"  dense v-model="participante.mae" label="* Nome da mãe" :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-select
                                  :readonly="somente_leitura" 
                                  label="* Nacionalidade"
                                  v-model="participante.id_nacionalidade"
                                  :items="nacionalidade"
                                  item-text="descricao"
                                  item-value="id"
                                  dense
                                  :rules="Rules.Required"
                                > 
                                </v-select>
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-select
                                  :readonly="somente_leitura" 
                                  label="* Estado civil"
                                  v-model="participante.id_estado_civil"
                                  :items="estado_civil"
                                  item-text="descricao"
                                  item-value="id" 
                                  dense
                                  :rules="Rules.Required"
                                > 
                                </v-select>
                              </v-col>  
                                
                            </v-row>
                          </div>  
                        </v-card-text>

                        <v-card-text>
                          <v-data-table
                            :headers="header"
                            :items="documentos_requerimento"
                            class="elevation-1 table"
                            no-data-text="Não há nenhum arquivo"
                            :items-per-page="-1"
                            :loading="loading.anexo"   
                            loading-text="Carregando arquivos..."
                            hide-default-footer
                            item-key="id_req_doc"
                            >


                            <template v-slot:[`item.clipe`]="{ item }">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small class="mr-2"  @click="selecionarArquivo(item.id_tipo_documento, item.id_req_doc)" >
                                    <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-paperclip</v-icon>
                                  </v-btn>
                                </template>
                                <span>Anexar</span>
                              </v-tooltip>
                              <v-icon color="orange"  elevation="4" v-if="item.situacao === 'E'">mdi-alert-circle-outline</v-icon>
                              <v-icon color="green"   elevation="4" v-else-if="item.situacao === 'D'">mdi-check-circle-outline</v-icon>
                              <v-icon color="primary" elevation="4" v-else></v-icon>
                            </template>
                          </v-data-table> 
                        </v-card-text>  
                      </v-card>

                      <v-card class="mt-5" >
                        <div v-for="dependente in dependentes">
                            <v-card-text class="text-h5" v-if="dependente.documentos.length > 0">
                                Documentos necessários - {{ dependente.nome }}
                            </v-card-text>
                            <v-card-text v-if="dependente.documentos.length > 0">
                                <v-data-table
                                    :headers="header"
                                    :items="dependente.documentos"
                                    class="elevation-1 table"
                                    no-data-text="Não há nenhum arquivo"
                                    :items-per-page="-1"
                                    :loading="loading.anexo"   
                                    loading-text="Carregando arquivos..."
                                    hide-default-footer
                                    item-key="id_req_doc"
                                    >


                                    <template v-slot:[`item.clipe`]="{ item }">
                                      {{item.id_req_doc}}
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon small class="mr-2"  @click="selecionarArquivoDep(item.id_tipo_documento, item.id_req_doc)" >
                                            <v-icon 
                                            v-bind="attrs"
                                            v-on="on"
                                            >mdi-paperclip</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Anexar</span>
                                    </v-tooltip>
                                    
                                    <v-icon color="orange"  elevation="4" v-if="item.situacao === 'E'">mdi-alert-circle-outline</v-icon>
                                    <v-icon color="green"   elevation="4" v-else-if="item.situacao === 'D'">mdi-check-circle-outline</v-icon>
                                    <v-icon color="primary" elevation="4" v-else></v-icon>
                                    </template>
                                </v-data-table> 
                            </v-card-text> 
                        </div>
                      </v-card>
                    </v-card-text> 
                  </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row> 

       

        <!-- Fim Etapa 2 -->

        


        <v-row v-if="participante.cpf != null && !loading"> 
          <v-col>
              <v-toolbar flat dense tile >
                <v-btn v-if="etapa > 0" rounded class="mr-3 " color="tertiary"   @click="voltar"> << Voltar </v-btn>
                <v-btn v-if="!somente_leitura && (etapa > 0 && etapa < 2)" rounded class="mr-3 " color="primary"   @click="salvaRequerimento('L')">  Salvar </v-btn>
                <v-btn v-if="!somente_leitura && (etapa == 2) && pode_enviar" rounded class="mr-3 " color="green" dark  @click="EnviaCodigo">  Finalizar </v-btn>
                <v-btn v-if="etapa > 0 && etapa < 2" rounded class="mr-3 " color="tertiary"   @click="avancar">  Avançar >></v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="!somente_leitura && etapa > 0" rounded class="mr-3 " color="grey"   @click="confirmaCancelamento">  Cancelar requerimento </v-btn>
                
              </v-toolbar>     
          </v-col>
        </v-row>

        <div class="text-center">  
          <v-dialog
            v-model="mostra_codigo"
            width="600"  
            persistent 
          >     

            <v-card>
              <v-card-title class="text-h5 primary white--text">
                Código de confirmação
              </v-card-title>

              <v-card-text class="mt-5">
                Um código de confirmação foi enviado para o endereço {{participante.email_pessoal}}. Informe o código de confirmação abaixo para finalizar seu requerimento de alteração de dados cadastrais.
              </v-card-text>

              <v-card-text class="mt-5">
                <div class="ma-auto position-relative" style="max-width: 300px">
                  <v-otp-input
                    length="6"
                    type="number"
                    
                    v-model="codigo_confirmacao"
                    
                  ></v-otp-input>
                </div>  
              </v-card-text>

              <v-card-text v-if="mostra_msg_codigo_invalido">
                <div class="text-center red--text text-h5">
                  {{ msg_codigo }}
                </div>
              </v-card-text>

              <v-card-text >
                <div class="text-center" >
                    <v-btn  dark text color="#0D47A1" @click="ReenviarCodigo" > Enviar novo código </v-btn>
                </div>
              </v-card-text>
             

              <v-card-actions>
                <v-btn
                  color="grey"
                  rounded
                  @click="mostra_codigo = false"
                >
                  Voltar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  dark
                  rounded
                  @click="VerificaCodigo"
                  :loading="loading_codigo"
                >
                  Confirmar
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>
        </div>
        

        

        

        <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
          <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>

        <v-overlay
          absolute
          :value="dialog"
        >
        </v-overlay>

        <v-snackbar
          v-model="dialog"
          multi-line
          centered
          :color="cor_dialogo"
          timeout="-1"
          max-width="400"
          min-height="140"
          vertical
        >
          

          <template v-slot:default>
            <div v-html="mensagem"> </div>
          </template>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnOk(titulo)"
              v-if="!simnao"
            >
              Ok
            </v-btn>
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnSim(titulo)"
              v-if="simnao"
            >
              Sim
            </v-btn>
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnNao(titulo)"
              v-if="simnao"
            >
              Não
            </v-btn>
          </template>
        </v-snackbar>

        

      </v-form> 

      <v-file-input
          v-if="!loading && etapa == 2"
          truncate-length="150"
          accept='.pdf,.png,.jpg,.jpeg'
          v-model="arquivo"
          id="fileUpload1"
          :rules="Rules.Arquivo"
          hide-input
          prepend-icon=""
          @change="enviarArquivo()"
        ></v-file-input>

        <v-file-input
          v-if="!loading && etapa == 2"
          truncate-length="150"
          accept='.pdf,.png,.jpg,.jpeg'
          v-model="arquivo"
          id="fileUpload2"
          :rules="Rules.Arquivo"
          hide-input
          prepend-icon=""
          @change="enviarArquivoDep()"
        ></v-file-input>

  </v-container>
</template>

<script>
   export default {

      data: function(){
         return {
            titulo_pagina: "Recadastramento",
            label: "Percent",
            focus: false,
            Rules:{
              Required: [v => !!v || 'Obrigatório',],
              Arquivo: [v => !!v || 'Escolha um arquivo para enviar',],
            },
            multiLine: true,
            overlay:false,
            absolute: false,
            form_valido:true,
            loading: false,
            mensagem: '',
            menu_aniv: false,
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            dialog: false,
            simnao: false,
            cor_dialogo: '#B00020',
            titulo: null,
            idade_titular: null,
            confirmou_idade: true,
            protocolo: null,
            ultima_atualizacao:null,
            participante:{
              id: null,
              cpf: null,
              nome: null,
              data_nascimento: "",
              sexo: null,
              id_nacionalidade: null,
              naturalidade: null,
              id_estado_civil: null,
              id_escolaridade: null,
              pep: null,
              aposentado_inss: null,
              responsavel_fatca: null,
              pai:null,
              mae: null, 
              estado: null,
              cep: '',
              celular: null,
              cidade: null,
              telefone: null,
              bairro: null,
              email_pessoal: null,
              email_extra: null,
              endereco: null,
              numero: null,
              complemento: null,
              id_pais: null
            },
            participante_planos: [],
            empresas: [],
            dependentes: [],
            dependentes_planos:[],
            planos:[],
            planos_aux:[],
            nacionalidade:[],
            estado_civil:[],
            parentesco:[],
            paises:[],
            plano1:null,
            plano2:null,
            plano3:null,
            escolaridade:[],
            tipoTempoServico:[],
            documentos_requerimento: [],
            sim_nao:[
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"},
            ],
            sim_nao_vazio:[
              {id:"", descricao: ""},
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"},
            ],
            estados:["", "AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
            sexo:[
              {id:"M", descricao:"MASCULINO"},
              {id:"F", descricao:"FEMININO"},
            ],
            teste: 0,
            aposentado_pensionista: null,
            eh_assistido_ps2: false,
            id_pessoa_assistido_ps2: null,
            etapa:0,
            email_hint: "",
            email_erro:false,
            id_plano:null,
            id_nuvem: null,
            id_requerimento: null,
            requerimento: {situacao: ''},
            exibe_escolha: false,
            id_tipo_documento: null,
            id_req_doc: null,
            opcoes: [
              {id: 1, descricao: "Escolher um documento no meu computador. Tamanho máximo "+this.$store.state.max_file_size+" MB"},
              {id: 2, descricao: "Escolher um documento que está na nuvem do participante"}
            ],
            escolhido: 1,
            id_protocolo_ged: null,
            arquivo: null,
            pode_iniciar: false,
            pode_continuar: false,
            pode_enviar: false,
            precisa_documentos: false,
            email_valido: false,
            mostra_codigo: false,
            codigo_confirmacao: '',
            loading_codigo: false,
            mostra_msg_codigo_invalido: false,
            msg_codigo: '',
            documentos_bnf: [],
            header: [
              
                {
                    text: 'Documento',
                    align: 'start',
                    sortable: false,
                    value: 'desc_tipo_documento',
                    width: 220,
                    class: 'title white--text'
                },
                {
                    text: 'Arquivo',
                    value: 'arquivo',
                    sortable: false,
                    width: 180,
                    class: 'title white--text'
                },
                {
                    text: 'Situação',
                    value: 'desc_situacao', 
                    sortable: false,
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: '',
                    align: 'end',
                    value: 'clipe',
                    sortable: false,
                    width: 40,
                    class: 'title white--text'
                },
            ],

         }
      },


      created: function() {

        this.loading = true;

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params); 

        let qtd = this.$store.state.planos_usuario.length;
        for(let i=0; i<qtd; i++){
          if(this.$store.state.planos_usuario[i].id_plano == 2 && this.$store.state.planos_usuario[i].situacao == 'ASSISTIDO'){
            this.id_pessoa_assistido_ps2 = this.$store.state.planos_usuario[i].id_pessoa;
            this.eh_assistido_ps2 = true;
          }
        }

        this.recuperaTabelas();
        this.consultaRequerimento();


      },

      
      methods: {

        //#####################
        //Regras de validação do formulário
        //####################
        Rateio: function(id_plano, numero, designado) {
          let numero_aux = numero.replace(",",".");
          if(!isNaN(numero_aux)){
            let perc_aux = 0;
            let soma = 0;
            let qtd_dep = this.dependentes.length;
            for(let i=0; i<qtd_dep; i++ ){
              for (let x = 0; x < this.dependentes[i].planos.length; x++) {
                if(this.dependentes[i].planos[x].id_plano == id_plano ){
                  if(this.dependentes[i].planos[x].percentual.indexOf(",") >= 0){
                    perc_aux = this.dependentes[i].planos[x].percentual.replace(",",".");
                  }else{
                    perc_aux = this.dependentes[i].planos[x].percentual;
                  }
                  soma = soma + parseFloat(perc_aux);
                }
              }
            }
             

            let aprovado = false;
            if(soma ==0){
               return  true ;
            }

            if(soma ==100){
              if(!isNaN(numero_aux)){
                if(designado == 'S' && parseFloat(numero_aux) == 0){
                  return  false || 'Percentual deve ser maior que zero'  //false mostra a mensagem
                }
                if(designado == 'N' && parseFloat(numero_aux) != 0){
                  return  false || 'Percentual deve ser zero'  //false mostra a mensagem
                }

              }
              return  true ;

            }else{
              return  false || 'Somatório deve ser 100%'  //false mostra a mensagem
            }

            
          }else{
            return false || 'Somente números'
          }  
        },


        Finalidade: function(planos, dependente_irrf) {

          let aprovado = true;
          let mensagem = '';

          let qtd = planos.length;

          let tem_finalidade = false;
          for( let i = 0; i<qtd; i++ ){
            if(planos[i].beneficiario == 'S' || planos[i].designado == 'S'){
              tem_finalidade = true;
            }
          }

          if(!tem_finalidade && dependente_irrf != 'S'){
            aprovado = false;
            mensagem = 'Dependente deve ser beneficiário ou designado';
          }

          return  [aprovado || mensagem];
          
        },

         pepObrigatorio: function() {

          let aprovado = true;
          let mensagem = '';


          if(this.participante.pep == "" || this.participante.pep == null || this.participante.pep == 0){
            aprovado = false;
            mensagem = 'Obrigatório';
          }

          return  [aprovado || mensagem];
          
        },

        umDosDois(info1, info2){

          let aprovado = true;
          let mensagem = '';

          if(info1 == null){
            info1 = '';
          }

          if(info1 != '' && info2){

            aprovado = false;
            mensagem = 'Informe número ou assinale TIN não disponível';
          }

          return  [aprovado || mensagem];

        },

        
        maxLength: function(max) {
          return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
        },


        telefone: function(telefone) {
          
          let aprovado = true;
          let mensagem = '';

          if(telefone != null){

            if(telefone.length < 10){
              aprovado = false;
              mensagem = 'Mínimo: ddd + 8 números';
            }

            if(telefone.indexOf('0000000') != -1 || 
              telefone.indexOf('1111111') != -1 || 
              telefone.indexOf('2222222') != -1 || 
              telefone.indexOf('3333333') != -1 || 
              telefone.indexOf('4444444') != -1 || 
              telefone.indexOf('5555555') != -1 || 
              telefone.indexOf('6666666') != -1 || 
              telefone.indexOf('7777777') != -1 || 
              telefone.indexOf('8888888') != -1 || 
              telefone.indexOf('9999999') != -1 || 
              telefone.indexOf('1234567') != -1
              ){

              aprovado = false;
              mensagem = 'Número inválido';
            }
          }  

          return  aprovado || mensagem
        },
        cep: function(cep) {
          
          let aprovado = true;
          let mensagem = '';

          if(this.participante.id_pais == 31){

            if(cep.length < 8){
              aprovado = false;
              mensagem = 'Mínimo 8 números';
            }

            if(cep.length < 1){
              aprovado = false;
              mensagem = 'Obrigatório';
            }

            if(cep.indexOf('0000000') != -1 || 
              cep.indexOf('1111111') != -1 || 
              cep.indexOf('2222222') != -1 || 
              cep.indexOf('3333333') != -1 || 
              cep.indexOf('4444444') != -1 || 
              cep.indexOf('5555555') != -1 || 
              cep.indexOf('6666666') != -1 || 
              cep.indexOf('7777777') != -1 || 
              cep.indexOf('8888888') != -1 || 
              cep.indexOf('9999999') != -1 || 
              cep.indexOf('1234567') != -1
              ){

              aprovado = false;
              mensagem = 'CEP inválido';
            }
          }

          return  aprovado || mensagem
        },


        email: function(email) {

          
          var resultado = true;
          var mensagem = '';

          if (email != null && email != '' ){
            var upper_email = email.toUpperCase();
            
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(upper_email))
            {
              resultado = false;
              mensagem = 'E-mail inválido';
            }



            if( upper_email.indexOf("SERPROS.COM") != -1 || upper_email.indexOf(".GOV.BR") != -1 ){
              resultado = false;
              mensagem = 'use seu e-mail particular';
            }
            
          }
          
          
          return  resultado || mensagem
        },


        aposInss: function(valor, sexo) {

          var resultado = true;
          var mensagem = '';
          var idademinima = 0;

          if (valor != null){
            if(valor == 'S'){

              if(sexo == 'F'){
                idademinima = 44;
              }else{
                idademinima = 49;
              }

              if(this.idade(this.participante.data_nascimento) < idademinima){
                resultado = false;
                mensagem = 'Idade inferior a '+idademinima+' anos';
              }
            }            
          }
          
          return  resultado || mensagem
        },
        DataMenor: function(data1,data2) {
        
          if(data2 != null && data2 != '' ){
            return [(data2 >= data1) || 'Demissão menor que Admissão']
          }else{
            return [true]
          }
          
        },
        IdadeTitular: function(data_nascimento) {
          this.menu_aniv = false;

          if(this.idade(data_nascimento) < 18){
            this.titulo = 'Atenção';
            this.mensagem = 'Esta data de nascimento indica uma pessoa com idade inferior a 18 anos. Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;   
          }

          if(this.idade(data_nascimento) > 80){
            this.titulo = 'Atenção';
            this.mensagem = 'Esta data de nascimento indica uma pessoa com idade superior a 80 anos. Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;   
          }

        },
        IdadeParente: function(parentesco, data_titular, data_depend) {

          if(parentesco == 7){
            let ano_aux = parseInt(data_titular.substring(0,4))+10;
            data_titular = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);
            if(data_depend < data_titular){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade entre pai(titular) e filho(dependente) inferior a 10 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true;   
            }
          }

          if(parentesco == 5){
            
            let ano_aux = parseInt(data_titular.substring(0,4))-18;
            data_titular = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend > data_titular){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade entre pai(dependente) e filho(titular) inferior a 18 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }
          }

          if(parentesco > 0 && parentesco < 5){
            
            let ano_aux = parseInt(data_titular.substring(0,4))-20;
            let data_aux = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend < data_aux){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade superior a 20 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }

            ano_aux = parseInt(data_titular.substring(0,4))+20;
            data_aux = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend > data_aux){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade superior a 20 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }


          }


          
        },
        Bigamia: function(){
          let soma = 0;
          for (let x = 0; x < this.dependentes.length; x++) {
            if(this.dependentes[x].id_parentesco == 1 || this.dependentes[x].id_parentesco == 3){
              soma = soma + 1;
            }
          } 

          if(soma > 1){
            this.titulo = 'Atenção';
            this.mensagem = 'Existem multiplos cônjuges ou companheiros cadastrados. '+ 
                            'Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        },
        lgbt: function(sexo, parentesco){
          if(parentesco > 0 && parentesco < 5){
            if(sexo == this.participante.sexo){
              this.titulo = 'Atenção';
              this.mensagem = 'Sexo do cônjuge ou companheiro igual ao sexo do titular. Verifique se esta informação está correta.';
              this.cor_dialogo ="#455A64";
              this.simnao = false;
              this.dialog = true;
            }
          }
          
        },
        IdadeAdmissao: function(data_admissao) {

          if(data_admissao != null){
            if(this.idadeNaAdmissao(this.participante.data_nascimento, data_admissao) < 16){
              this.titulo = 'Atenção';
              this.mensagem = 'Esta data de admissão indica que a pessoa tinha idade inferior a 16 anos na admissão. Verifique se esta informação está correta.';
              this.cor_dialogo ="#455A64";
              this.simnao = false;
              this.dialog = true;   
            }
          }
        },
        DataMaiorQAtual:  function(data1){
            let current = new Date();
            let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            return (data1 <= cDate) || 'Data superior a data atual'
        },
        cpf: function(cpf){
          let valido = this.validarCPF(cpf);
          return valido || 'CPF inválido'
        },
        VerificaPais: function(){

          if(this.participante.id_pais != 31){
             this.participante.cep = '';
             this.participante.estado = '';
          }
        },


        Estado: function(cep, uf){

          let resultado = true;
          let mensagem = '';

          if(this.participante.id_pais == 31){
             
             if(this.participante.estado == ''){
                resultado = false;
                mensagem = 'Obrigatório';
             }else{
                let cep_estado = cep.substring(0,5);

                if(cep_estado < '01000'){resultado = false;} 
                if(cep_estado >= '01000' && cep_estado <= '19999' && uf != 'SP'){resultado = false;}
                if(cep_estado >= '20000' && cep_estado <= '28999' && uf != 'RJ'){resultado = false;} 
                if(cep_estado >= '29000' && cep_estado <= '29999' && uf != 'ES'){resultado = false;}
                if(cep_estado >= '30000' && cep_estado <= '39999' && uf != 'MG'){resultado = false;}
                if(cep_estado >= '40000' && cep_estado <= '48999' && uf != 'BA'){resultado = false;}
                if(cep_estado >= '49000' && cep_estado <= '49999' && uf != 'SE'){resultado = false;}
                if(cep_estado >= '50000' && cep_estado <= '56999' && uf != 'PE'){resultado = false;}
                if(cep_estado >= '57000' && cep_estado <= '57999' && uf != 'AL'){resultado = false;}
                if(cep_estado >= '58000' && cep_estado <= '58999' && uf != 'PB'){resultado = false;}
                if(cep_estado >= '59000' && cep_estado <= '59999' && uf != 'RN'){resultado = false;}
                if(cep_estado >= '60000' && cep_estado <= '63999' && uf != 'CE'){resultado = false;}
                if(cep_estado >= '64000' && cep_estado <= '64999' && uf != 'PI'){resultado = false;}
                if(cep_estado >= '65000' && cep_estado <= '65999' && uf != 'MA'){resultado = false;}
                if(cep_estado >= '66000' && cep_estado <= '68899' && uf != 'PA'){resultado = false;}
                if(cep_estado >= '68900' && cep_estado <= '68999' && uf != 'AP'){resultado = false;}
                if(((cep_estado >= '69000' && cep_estado <= '69299') || (cep_estado >= '69400' && cep_estado <= '69899')) && uf != 'AM'){resultado = false;}
                if(cep_estado >= '69300' && cep_estado <= '69399' && uf != 'RR'){resultado = false;}
                if(cep_estado >= '69900' && cep_estado <= '69999' && uf != 'AC'){resultado = false;}
                if(((cep_estado >= '70000' && cep_estado <= '72799') || (cep_estado >= '73000' && cep_estado <= '73699')) && uf != 'DF'){resultado = false;}
                if(((cep_estado >= '72800' && cep_estado <= '72999') || (cep_estado >= '73700' && cep_estado <= '76799')) && uf != 'GO'){resultado = false;}
                if(cep_estado >= '76800' && cep_estado <= '76999' && uf != 'RO'){resultado = false;}
                if(cep_estado >= '77000' && cep_estado <= '77999' && uf != 'TO'){resultado = false;}
                if(cep_estado >= '78000' && cep_estado <= '78999' && uf != 'MT'){resultado = false;}
                if(cep_estado >= '79000' && cep_estado <= '79999' && uf != 'MS'){resultado = false;}
                if(cep_estado >= '80000' && cep_estado <= '87999' && uf != 'PR'){resultado = false;}
                if(cep_estado >= '88000' && cep_estado <= '89999' && uf != 'SC'){resultado = false;}
                if(cep_estado >= '90000' && cep_estado <= '99999' && uf != 'RS'){resultado = false;}
                if(cep_estado > '99999'){resultado = false;} 

                if(!resultado){
                    mensagem = 'UF não corresponde ao CEP';
                }
             }

             
          }
    
          return resultado || mensagem 
        },
        //#####################
        //Filtro de dependentes
        //####################
        FiltraPlanoDependente: function(id_dependente){
          return this.dependentes_planos.filter(function(plano) {
            return plano.id_dependente == id_dependente;
          })
        },
        //#####################
        //click dos botões da tela
        //####################
        AddEmpresa(){
       
          this.empresas.push({id: Math.round(Math.random()*-10000,0), 
                              nr_sequencial: null,
                              nome_empresa:"", 
                              id_tipo_servico:null, 
                              data_admissao:null, 
                              data_demissao:null, 
                              menu_admissao:false, 
                              menu_demissao:false});
        },



        DelEmpresa(id){

          var index =  this.empresas.map(x => {
            return x.id;
          }).indexOf(id); 

          this.empresas.splice(index, 1);
        },



        AddDependente(){
          let id_dependente = Math.round(Math.random()*-10000,0);
          
          if(this.dependentes == null){
            this.dependentes = [];
          }

          let dependente_planos = [];
          for (let x = 0; x < this.participante_planos.length; x++) {   
            dependente_planos.push({               
              id: Math.round(Math.random()*-10000,0),
              id_dependente: id_dependente,
              id_plano: parseInt(this.participante_planos[x].id_plano),
              beneficiario: "N",
              designado: "N",
              percentual: "0",
            });                      
          }

          this.dependentes.push({ id_requerimento: this.requerimento.id,
                                  id: id_dependente,
                                  id_pessoa_dep: null,
                                  id_parentesco: "",
                                  invalido: "N",
                                  nivel_superior: "N",
                                  nome: "",
                                  cpf: "",
                                  data_nascimento: "",
                                  menu_nascimento: false,
                                  invalido: "",
                                  sexo: "",
                                  id_escolaridade: "",
                                  estado: "",
                                  cidade: "",
                                  bairro: "",
                                  cep: "",
                                  endereco: "",
                                  numero: "",
                                  complemento: "",
                                  celular: "",
                                  telefone_alternativo: "",
                                  email_pessoal: "",
                                  email_extra: "",
                                  planos: dependente_planos,

          });

          
        },



        DelDependente(id){

          for( var i = 0; i < this.dependentes_planos.length; i++){                       
              if ( this.dependentes_planos[i].id_dependente === id) { 
                this.dependentes_planos.splice(i, 1); 
                  i--; 
              }
          }

          var index =  this.dependentes.map(x => {
            return x.id;
          }).indexOf(id); 

          this.dependentes.splice(index, 1);
        },



        BtnOk(){

          if(this.titulo == 'Alerta'){
            this.$router.push('/login'); 
          }else if(this.titulo == 'Requerimento Deferido'){  
            this.pode_continuar = false;
            this.pode_iniciar = true;
            this.etapa = 0;
          }else if(this.titulo == 'Requerimento Em Análise'){  
            this.pode_continuar = false;
            this.pode_iniciar = false;
            this.etapa = 0;
          }else{
            if(this.titulo != 'Atenção'){
              this.sair();
            }
          }

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
 
        }, 

        BtnSim(){

          if(this.titulo == 'Sucesso'){
            this.dialog = false;
            this.titulo = '';
            this.mensagem = '';
            this.sair();
          }

          if(this.titulo == 'Cancelar'){
            this.salvaRequerimento('N');
            
          }

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
          
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.overlay = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        },   

        sair(){

          this.$router.push('/');  

        }, 
        
        btnsair(){


          this.titulo = 'Sucesso';
          this.mensagem = 'Deseja sair do sistema?';
          this.cor_dialogo ="#455A64";
          this.simnao = true;
          this.dialog = true; 


        }, 

        confirmaCancelamento(){

          this.titulo = 'Cancelar';
          this.mensagem = 'Deseja cancelar o requerimento?';
          this.cor_dialogo ="#455A64";
          this.simnao = true;
          this.dialog = true; 

        },
 
        //#####################
        //chamadas nas APIs
        //####################
        consultaCEP(cep, pessoa) {
          //busca o cep
          if(cep.length == 8){
            this.axios({
              method: 'get',
              url: 'https://viacep.com.br/ws/'+cep+'/json/',
            })
            .then(response => {
                        if("cep" in response.data){
                            pessoa.endereco = response.data.logradouro.toUpperCase();
                            pessoa.estado = response.data.uf;
                            pessoa.cidade = response.data.localidade.toUpperCase();
                            pessoa.bairro = response.data.bairro.toUpperCase();

                        }
            })
            .catch(error => {
                    //Erro ao recuperar os dados da pessoa
                    this.TrataErros(error);
            }); 
          }

        },




        recuperaTabelas() {

          //busca a pessoa

          this.$API.get( '/api/cadastro/tabelas')
          .then(response => {

              this.planos = response.data.plano;                    
              this.nacionalidade = response.data.nacionalidade;
              this.estado_civil = response.data.estadoCivil; 
              this.escolaridade = response.data.escolaridade;
              this.tipoTempoServico = response.data.tipoTempoServico;
              this.parentesco = response.data.parentesco;
              this.paises = response.data.paises; 
            
    
          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 

        },

        consultaRequerimento(){

          var params = {
              cpf:  this.$store.state.pessoa_fisica.cpf,
          }  
          
          this.$API.post( '/api/requerimento/consulta',params)
          .then(response => {
            this.requerimento = response.data.requerimento;
            
            if(typeof this.requerimento.id_nuvem != 'undefined'){ 

              this.id_nuvem = this.requerimento.id_nuvem;

              if(this.requerimento.situacao == 'L' || this.requerimento.situacao == 'E'){
                this.pode_continuar = true;
              }else{
                this.pode_continuar = false;
              }

              if(this.requerimento.situacao == 'L' || this.requerimento.situacao == 'E' || this.requerimento.situacao == 'A' || this.requerimento.situacao == 'C'){
                this.pode_iniciar = false;
                this.mensagens = response.data.mensagens;
              }else{
                this.pode_iniciar = true;
              }

              


            }else{
              this.id_nuvem = null;
              this.requerimento = {situacao:''};
              this.pode_continuar = false;
              this.pode_iniciar = true;
              this.mensagens = [];
            }
            
            this.loading = false;
          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          });

        },


        iniciarRequerimento(){
          
          this.loading = true;

          let params = {
              id: null,
              id_plano: this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_plano,
              id_tipo:  14, 
              situacao: 'L',
              id_pessoa: this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_pessoa,
            };


          this.$API.post( '/api/requerimento/novo', params)
          .then(response => {

            this.requerimento = response.data.requerimento;
            this.id_nuvem = response.data.requerimento.id_nuvem;
            this.id_protocolo_ged = response.data.id_protocolo_ged;
            this.participante = response.data.participante; 
            this.participante_planos = response.data.participante_planos;
            this.empresas = response.data.empresas;            
            this.dependentes = response.data.dependentes;
            this.documentos_requerimento = response.data.documentos;


            let qtd_docs = this.documentos_requerimento.length;
            for(var x=0; x<qtd_docs; x++){
              if( this.documentos_requerimento[x].desc_tipo_documento == "OUTROS"){
                 this.documentos_requerimento[x].desc_tipo_documento = "OUTROS (opcional)";
              }
            }
            
            //daqui pra baixo preenche os campos da tela com os planos
            
            let qtd = this.participante_planos.length;
            if(qtd > 0){
              for (let i = 0; i < this.planos.length; i++) {
                if(this.planos[i].id == this.participante_planos[0].id_plano){
                    this.plano1 = this.planos[i].sigla;
                }
              }
            }else{
              this.plano1 = '';
            } 

            if(qtd > 1){
              for (let i = 0; i < this.planos.length; i++) {
                if(this.planos[i].id == this.participante_planos[1].id_plano){
                    this.plano2 = this.planos[i].sigla;
                }
              }
            }else{
              this.plano2 = '';
            }

            if(qtd > 2){
              for (let i = 0; i < this.planos.length; i++) {
                if(this.planos[i].id ==this.participante_planos[2].id_plano){
                    this.plano3 = this.planos[i].sigla;
                }
              }
            }else{
              this.plano3 = '';
            }

            this.id_plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_plano;

            this.etapa = 1;
            this.loading = false; 
                        
          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          });  

          

        },




        buscaRequerimento(){

          this.loading = true;  

          this.$API.get( '/api/requerimento/show/'+this.requerimento.id)
          .then(response => {    
                        
              this.participante = response.data.participante;
              this.empresas = response.data.empresas;
              this.participante_planos = response.data.participante_planos;
              this.dependentes = response.data.dependentes;
              this.documentos_requerimento = response.data.documentos;
              this.id_protocolo_ged = response.data.id_protocolo_ged;
              this.mensagens = response.data.mensagens;

              let qtd_docs = this.documentos_requerimento.length;
              for(var x=0; x<qtd_docs; x++){
                if(this.documentos_requerimento[x].desc_tipo_documento == "OUTROS"){
                  this.documentos_requerimento[x].desc_tipo_documento = "OUTROS (opcional)";
                }
              }
              
              
              this.etapa = 1;
              this.loading = false;  

              

          }) 
          .catch(error => {
              this.TrataErros(error)
          }); 

        },


        salvaRequerimento(situacao){

          this.form_valido = false;
          
          if(this.$refs.form.validate()){

            this.loading = true;

            let etapa2 = false;

            if(situacao == 'L2'){
                situacao = 'L';
                etapa2 = true;
            }
          
            let id_requerimento = null;
            if(this.requerimento != null){
              id_requerimento = this.requerimento.id;
            }
            
            let data = {
              id: id_requerimento,
              id_plano: this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_plano,
              id_tipo:  14, 
              id_nuvem: this.id_nuvem,
              situacao: situacao,
              usuario:  this.participante.cpf,
              detalhes: this.participante,
              empresas: this.empresas,
              dependentes: this.dependentes, 
              documentos: this.documentos_requerimento,
              id_pessoa: this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_pessoa,
              email_mensagem:this.$store.state.pessoa_fisica.email_pessoal
            }; 
            
            
            this.$API.post( '/api/requerimento/salvar', data)
            .then(response => {

              if(response.data.codigo == 'ok'){

                if(situacao == 'L'){
                  //this.documentos_bnf = response.data.documentos_bnf;
                  this.dependentes = response.data.dependentes;

                  this.alerta = 'Requerimento gravado com sucesso!'; 
                  this.alerta_color = "green";
                  this.exibe_alerta = true;
                  if(etapa2){
                    this.etapa = 2;
                    this.VerificaSePodeEnviar();
                  }
                  
                }
                
                if(situacao == 'N'){
                  this.mensagens = [];
                  this.participante = [];
                  this.empresas = [];
                  this.dependentes = [];
                  this.alerta = 'Requerimento cancelado!';
                  this.alerta_color = "green";
                  this.exibe_alerta = true;
                  this.requerimento.situacao = 'N';
                  this.pode_continuar = false;
                  this.pode_iniciar = true;
                  this.etapa = 0;
                }

                if(situacao == 'A' && this.participante.alterar_dados_verificados == 'S'){
                  
                  this.mensagens = response.data.mensagens;
                  this.alerta = 'Requerimento enviado para análise!';
                  this.alerta_color = "green";
                  this.exibe_alerta = true;
                  this.requerimento.situacao = 'A';
                  this.pode_continuar = false;
                  this.pode_iniciar = false;
                  this.etapa = 0;
                  this.$store.commit('setrecadanalise', 'S');
                  
                }

                if(situacao == 'A' && this.participante.alterar_dados_verificados == 'N'){

                  this.mensagens = [];
                  this.participante = [];
                  this.empresas = [];
                  this.dependentes = [];
                  if(response.data.msg_beneficiarios != 'ok'){
                    this.titulo = 'Requerimento Deferido';
                    this.mensagem = 'Requerimento deferido! ' + response.data.msg_beneficiarios;
                    this.cor_dialogo ="green";
                    this.simnao = false;
                    this.dialog = true;   
                    this.requerimento.situacao = 'F';
                    this.$store.commit('setrecad', 1);
                  }else{
                    this.alerta = 'Requerimento deferido!';
                    this.alerta_color = "green";
                    this.exibe_alerta = true;
                    this.requerimento.situacao = 'F';
                    this.pode_continuar = false;
                    this.pode_iniciar = true;
                    this.etapa = 0;
                    this.$store.commit('setrecad', 1);
                  }
                  
                  
                  
                }

              }else{
                this.TrataErros(response)
              }

              this.loading = false;  

            }) 
            .catch(error => {
                this.TrataErros(error)
            });  

          }else{
            this.form_valido = true;
            this.overlay = false;
            this.titulo = 'Atenção';
            this.mensagem = 'Existem problemas no preenchimento. Favor revisar o formulário.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        }, 


        




        //#####################
        //funções utilitárias
        //####################
        idade(data_aniversario = '') {
          var d = new Date,
              ano_atual = d.getFullYear(),
              mes_atual = d.getMonth() + 1,
              dia_atual = d.getDate(),
              ano_aniversario = data_aniversario.substring(0,4),
              mes_aniversario = data_aniversario.substring(5,7),
              dia_aniversario = data_aniversario.substring(8,10),
              quantos_anos = ano_atual - ano_aniversario;

          if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
              quantos_anos--;
          }
          quantos_anos = parseInt(quantos_anos);

          return quantos_anos < 0 ? 0 : quantos_anos;
        },


        idadeNaAdmissao(data_aniversario = '', data_admissao) {

          var ano_admissao = data_admissao.substring(0,4),
              mes_admissao = data_admissao.substring(5,7),
              dia_admissao = data_admissao.substring(8,10),
              ano_aniversario = data_aniversario.substring(0,4),
              mes_aniversario = data_aniversario.substring(5,7),
              dia_aniversario = data_aniversario.substring(8,10),
              quantos_anos = ano_admissao - ano_aniversario;

          if (mes_admissao < mes_aniversario || mes_admissao == mes_aniversario && dia_admissao < dia_aniversario) {
              quantos_anos--;
          }
          quantos_anos = parseInt(quantos_anos);

          return quantos_anos < 0 ? 0 : quantos_anos;
        },


        validarCPF(cpf) {	

          if(typeof cpf == "string"){
            cpf = cpf.replace(/[^\d]+/g,'');	
            if(cpf == '') return false;	
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 || 
              cpf == "00000000000" || 
              cpf == "11111111111" || 
              cpf == "22222222222" || 
              cpf == "33333333333" || 
              cpf == "44444444444" || 
              cpf == "55555555555" || 
              cpf == "66666666666" || 
              cpf == "77777777777" || 
              cpf == "88888888888" || 
              cpf == "99999999999")
                return false;		
            // Valida 1o digito	
            let add = 0,
                i = 0,
                rev = 0;	

            for (i=0; i < 9; i ++)		
              add += parseInt(cpf.charAt(i)) * (10 - i);	
              rev = 11 - (add % 11);	
              if (rev == 10 || rev == 11)		
                rev = 0;	
              if (rev != parseInt(cpf.charAt(9)))		
                return false;		
            // Valida 2o digito	
            add = 0;	
            for (i = 0; i < 10; i ++)		
              add += parseInt(cpf.charAt(i)) * (11 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)	
              rev = 0;	
            if (rev != parseInt(cpf.charAt(10)))
              return false;		
            return true;  
          }else{
            return true; 
          }
           
        },


        formatDate (date) {
          if (!date) return null

          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        },


        avancar(){

            if(this.etapa == 0){
                this.etapa++;
            }

            if(this.etapa == 1){
                this.salvaRequerimento('L2');
            }
          
          
        },

        voltar(){
          if(this.etapa > 0){
            this.etapa--;
            this.pode_continuar = true;
            this.pode_iniciar = false;
          }
        },



       selecionarArquivo(id_tipo_documento, id_req_doc) {
            
            this.id_tipo_documento = id_tipo_documento;
            this.id_req_doc = id_req_doc;
            document.getElementById("fileUpload1").click();
            
        },

        selecionarArquivoDep(id_tipo_documento, id_req_doc) {

            this.id_tipo_documento = id_tipo_documento;
            this.id_req_doc = id_req_doc;
            document.getElementById("fileUpload2").click(); 
            
        },



        enviarArquivo() {

          if(event.target.files[0].size <= this.$store.state.max_file_size * 1048576 ){

            this.loading = true;

            let formData = new FormData();
            formData.append('id_nuvem', this.id_nuvem);
            formData.append('id_tipo_documento',this.id_tipo_documento); 
            formData.append('id_req_doc',this.id_req_doc);
            formData.append('id_requerimento',this.requerimento.id);
            formData.append('id_protocolo_ged',this.id_protocolo_ged);
            formData.append('arquivo', this.arquivo);

            

            this.$API.post( '/api/requerimento/novodocumento',  formData)
            .then(response => {
              if(response.data.codigo == 'ok'){

                let qtd = this.documentos_requerimento.length;
                for(let i=0; i<qtd; i++){
                  if(this.documentos_requerimento[i].id_req_doc == this.id_req_doc){
                    this.documentos_requerimento[i].id_documento = response.data.id_documento;
                    this.documentos_requerimento[i].desc_situacao = response.data.desc_situacao;
                    this.documentos_requerimento[i].id_situacao = response.data.id_situacao;
                    this.documentos_requerimento[i].arquivo = response.data.nome_documento;
                  }
                }
                this.alerta = 'Documento gravado com sucesso!';
                this.alerta_color = "green"; 
                this.id_tipo_documento = null;
                this.id_req_doc = null; 
                this.arquivo = null; 
                this.loading  = false;   
                this.exibe_alerta = true; 

                this.VerificaSePodeEnviar();
                
              }  
            }) 
            .catch(error => {
                this.TrataErros(error);

            });  
          }else{
            this.alerta = 'Documento muito grande! Tamanho máximo: '+this.$store.state.max_file_size+' MB';
            this.alerta_color = '#B00020';
            this.exibe_alerta = true;
          }

        },


        enviarArquivoDep() {

          if(event.target.files[0].size <= this.$store.state.max_file_size * 1048576 ){

            this.loading = true;

            let formData = new FormData();
            formData.append('id_nuvem', this.id_nuvem);
            formData.append('id_tipo_documento',this.id_tipo_documento); 
            formData.append('id_req_doc',this.id_req_doc);
            formData.append('id_requerimento',this.requerimento.id);
            formData.append('id_protocolo_ged',this.id_protocolo_ged);
            formData.append('arquivo', this.arquivo);

            this.$API.post( '/api/requerimento/novodocumentobnf',  formData)
            .then(response => {

              

              if(response.data.codigo == 'ok'){             
                
                let qtd_dep = this.dependentes.length;
                for(let x=0; x<qtd_dep; x++){
                  let qtd = this.dependentes[x].documentos.length;
                  for(let i=0; i<qtd; i++){
                    if(this.dependentes[x].documentos[i].id_req_doc == this.id_req_doc){
                       this.dependentes[x].documentos[i].id_documento = response.data.id_documento;
                       this.dependentes[x].documentos[i].desc_situacao = response.data.desc_situacao;
                       this.dependentes[x].documentos[i].id_situacao = response.data.id_situacao;
                       this.dependentes[x].documentos[i].arquivo = response.data.nome_documento;

                       
                    }
                  }
                } 

                

                
                 
                
                this.alerta = 'Documento gravado com sucesso!';
                this.alerta_color = "green"; 
                this.id_tipo_documento = null;
                this.id_req_doc = null; 
                this.arquivo = null; 
                this.loading  = false;   
                this.exibe_alerta = true; 

                this.VerificaSePodeEnviar();
                
              } else{
                this.alerta = 'Não foi possível salvar o documento!';
                this.alerta_color = "#B00020"; 
                this.id_tipo_documento = null;
                this.id_req_doc = null; 
                this.arquivo = null; 
                this.loading  = false;   
                this.exibe_alerta = true; 
              } 
            }) /*
            .catch(error => {
                this.TrataErros(error);

            });   */
          }else{
            this.alerta = 'Documento muito grande! Tamanho máximo: '+this.$store.state.max_file_size+' MB';
            this.alerta_color = '#B00020';
            this.exibe_alerta = true;
          }

        },


        VerificaSePodeEnviar(){

          this.pode_enviar = false;
          var pode = true;

          if(this.participante.alterar_dados_verificados == 'S'){
            
            if(this.requerimento.situacao == 'L' || this.requerimento.situacao == 'E'){
              for (var i = 0; i < this.documentos_requerimento.length; i++) {
                if(this.documentos_requerimento[i].id_situacao != 1 && this.documentos_requerimento[i].id_situacao != 4 && this.documentos_requerimento[i].id_situacao != 5){
                  if(!this.documentos_requerimento[i].id_situacao){
                    if(this.documentos_requerimento[i].id_tipo_documento == 2){
                      pode = false;
                    }
                      
                  }
                }
              }

            }
          }

          if(this.requerimento.situacao == 'L' || this.requerimento.situacao == 'E'){
            var qtd2 = this.dependentes.length;
            for(var x = 0; x < qtd2; x++){
              var qtd3 = this.dependentes[x].documentos.length;
              for (var i = 0; i < qtd3; i++) {
                if(this.dependentes[x].documentos[i].id_situacao != 1 && this.dependentes[x].documentos[i].id_situacao != 4 && this.dependentes[x].documentos[i].id_situacao != 5){
                  if(!this.dependentes[x].documentos[i].id_situacao){
                      pode = false;
                  }
                }
              }
            }
          }  
          

          this.pode_enviar = pode;
         
        },


        clickResponsavelFATCA(){
          if(this.participante.responsavel_fatca == "N"){
            this.participante.residente_fiscal_exterior = "N";
            this.participante.cidadao_americano = "N";
            this.participante.pais_pep_1 = "";
            this.participante.tin_1 = "";
            this.participante.tin_1_nao_se_aplica = false;
            this.participante.pais_pep_2 = "";
            this.participante.tin_2 = "";
            this.participante.tin_2_nao_se_aplica = false;
            this.participante.pais_pep_3 = "";
            this.participante.tin_3 = "";
            this.participante.tin_3_nao_se_aplica = false;


          }
        },


        clickResidenteExterior(){
          if(this.participante.residente_fiscal_exterior == "N"){
            this.participante.pais_pep_1 = "";
            this.participante.tin_1 = "";
            this.participante.tin_1_nao_se_aplica = false;
            this.participante.pais_pep_2 = "";
            this.participante.tin_2 = "";
            this.participante.tin_2_nao_se_aplica = false;
            this.participante.pais_pep_3 = "";
            this.participante.tin_3 = "";
            this.participante.tin_3_nao_se_aplica = false;
          }
        },


        EnviaCodigo(){

          this.codigo_confirmacao = '';

          if( this.$refs.form.validate() ){

            this.mostra_codigo=true;
            this.mostra_msg_codigo_invalido = false;
            this.msg_codigo = '';

            let data = {
              email: this.participante.email_pessoal
            }

            this.$API.post( '/api/requerimento/gerarcodigo', data);

          }else{
            this.form_valido = true;
            this.overlay = false;
            this.titulo = 'Atenção';
            this.mensagem = 'Existem problemas no preenchimento. Favor revisar o formulário.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        },


        VerificaCodigo(){

          this.loading_codigo = true;
          this.mostra_msg_codigo_invalido = false;

          let data = {
              codigo: this.codigo_confirmacao
            }

          this.$API.post( '/api/requerimento/validarcodigo',  data)
            .then(response => {
              
              if(response.data.msg == 'ok'){
                this.mostra_codigo=false;
                this.loading_codigo = false;
                this.mostra_msg_codigo_invalido = false;
                this.msg_codigo = '';
                this.salvaRequerimento('A');

              }else{
                this.loading_codigo = false;
                this.mostra_msg_codigo_invalido = true;
                this.msg_codigo = response.data.msg;
              }
                 
            }) 
            .catch(error => {
                this.TrataErros(error);

            });  

        },

        ReenviarCodigo(){

          this.codigo_confirmacao = '';
          this.mostra_msg_codigo_invalido = false;
          this.msg_codigo = '';
          this.alerta = 'Novo código enviado!';
          this.alerta_color = "green";
          this.exibe_alerta = true;
          this.EnviaCodigo();

        },


    /*    documentosBeneficiarios(){

          if(this.eh_assistido_ps2){

            let data = {
              id_pessoa: this.id_pessoa_assistido_ps2,
              dependentes: this.dependentes,
              dependentes_planos: this.dependentes_planos,
              id_requerimento: this.requerimento.id,
            }

            this.$API.post( '/api/requerimento/docsbnf',  data)
              .then(response => {
                
                this.documentos_bnf = response.data.documentos_bnf;

                this.VerificaSePodeEnviar();
                  
              }) 
              .catch(error => {
                  this.TrataErros(error);

              });  

          }else{
            this.VerificaSePodeEnviar();
          }

          


        },*/




        TrataErros(error){

          

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.simnao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if(typeof error.response === 'undefined'){
            console.log('Não retornou mensagem');
          }else{

            console.log(error.response);

            if('data' in error.response){
              if('mensagem' in error.response.data){
                if( error.response.data.mensagem == 'Token expirado'){
                  token_expirado = true;
                }  
              }

              if(token_expirado){
                this.mensagem = 'Sua sessão expirou. Faça um novo login.';
              } 
            }

            this.dialog = true;

            var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
          }
          
        } 
      },
   }
</script>